import React from "react"
import styled from 'styled-components'
import { Wrapper, CSecondary, CDark, above, TH2 } from "./global"
import Insta1 from "../images/instagram_01.jpg"
import Insta2 from "../images/instagram_02.jpg"
import Insta3 from "../images/instagram_03.jpg"
import Insta4 from "../images/instagram_04.jpg"
import Insta5 from "../images/instagram_05.jpg"

const Insta = styled.section`
    background: ${CSecondary};
    color: ${CDark};
    padding: 30px 0;
    text-align: center;
    ${above.small`
        padding: 100px 0;
    `}
`

const Photos = styled.div`
    display: grid;
    grid-template-columns: 1fr;
    gap: 20px;
    margin-top: 30px;
    img {
        width: 100%;
        height: auto;
        aspect-ratio: 1 / 1;
        object-fit: cover;
        border-radius: 6px;
    }
    ${above.small`
        grid-template-columns: 1fr 1fr;
    `}
    ${above.med`
        grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
        margin-top: 60px;
    `}
`

const Instagram = () => (
    <Insta>
        <Wrapper>
            <TH2>Follow Us On Instagram</TH2>
            <p>See #HoylakeAutos for our latest updates and happy customers!</p>
            <Photos>
                <img src={Insta1} width="256" height="256" alt="Instagram Photo" />
                <img src={Insta2} width="256" height="256" alt="Instagram Photo" />
                <img src={Insta3} width="256" height="256" alt="Instagram Photo" />
                <img src={Insta4} width="256" height="256" alt="Instagram Photo" />
                <img src={Insta5} width="256" height="256" alt="Instagram Photo" />
            </Photos>
        </Wrapper>
    </Insta>
  )
  
export default Instagram