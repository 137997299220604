import React from "react"
import { Link } from "gatsby"
import styled from 'styled-components'
import Layout from "../components/layout"
import SEO from "../components/seo"
import Alert from "../components/alert"
import HeaderImg from "../images/header_bg.png"
import IconWarn from "../images/icon_alert.svg"
import Service from "../components/service"
import { above, CLight, CPrimary, Wrapper } from "../components/global"
import Instagram from "../components/instagram"
import ServiceIcon1 from "../images/icon_service_bulbs.svg"
import ServiceIcon2 from "../images/icon_service_servicing.svg"
import ServiceIcon3 from "../images/icon_service_diagnostics.svg"
import ServiceIcon4 from "../images/icon_service_mot.svg"
import Quote from "../components/quote"

const Services = styled.section`
  display: grid;
  grid-template-columns: 1fr;
  gap: 30px;
  margin: 30px 0;
  ${above.small`
    grid-template-columns: 1fr 1fr;
    gap: 64px;
  `}
  ${above.med`
    grid-template-columns: 1fr 1fr 1fr 1fr;
    gap: 80px;
    margin: 100px 0;
  `}
`

const Highlight = styled.section`
  background: ${CPrimary};
  color: ${CLight};
  padding: 40px 0;
  font-size: 18px;
  text-align: center;
  span {
    font-weight: 700;
    text-transform: uppercase;
  }
  a {
    text-decoration: underline;
  }
`

const IndexPage = () => (
  <Layout pageTitle="Welcome To Hoylake Autos" pageBG={HeaderImg}>
    <SEO title="Home" />
    <Alert alertIcon={IconWarn} alertTitle="Covid-19" alertText="We can do contactless payments &amp; email invoices. We disinfect all keys and steering wheels etc before &amp; after we get in your vehicle!" />
    
    <Wrapper>
      <Services>
        <Service serviceIcon={ServiceIcon1} serviceTitle="Bulbs" serviceText="We stock a wide variety of bulbs for all the leading vehicle manufacturers." />
        <Service serviceIcon={ServiceIcon2} serviceTitle="Servicing" serviceText="We provide full inspection servicing for all vehicle manufacturers. We can also log online service schedules to keep within manufacturer specifications." />
        <Service serviceIcon={ServiceIcon3} serviceTitle="Diagnostics" serviceText="We have up to date market leading diagnostic software to help us diagnose any warning lights your vehicle may have illuminated." />
        <Service serviceIcon={ServiceIcon4} serviceTitle="Mot's" serviceText="We offer MOTs carried out by our local station. Repair work also carried out to ensure vehicle safety." />
      </Services>
    </Wrapper>

    <Instagram />

    <Highlight>
      <Wrapper>
        <p>Don't forget we offer a <span>Free</span> collect and deliver service of your vehicle. Available across Wirral. Please <Link to="/">contact us</Link> or <a href="/" title="Call Us">give us a call</a> for more details.</p>
      </Wrapper>
    </Highlight>

    <Quote />
    
  </Layout>
)

export default IndexPage
