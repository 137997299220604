import React from "react"
import styled from 'styled-components'
import { TH4, CPrimary, CDark } from "./global"

const ServiceBox = styled.section`
    h4 {
        margin: 30px 0 16px;
    }
    p {
        color: ${CDark};
        line-height: 1.5;
    }
`

const Service = ({ serviceIcon, serviceTitle, serviceText }) => (
    <ServiceBox>
        <img src={serviceIcon} width="72" height="72" alt="Service Icon" />
        <TH4 color={CPrimary}>{serviceTitle}</TH4>
        <p>{serviceText}</p>
    </ServiceBox>
  )
  
export default Service